.nav-bar {
  background: #181818;
  width: 100px;
  height: 100%;
  position: fixed;
  top: 0;
  color: white;
  z-index: 3;
  min-height: 500px;

  @media only screen and (max-width: 555px) {
    background: rgba(0, 0, 0, 0);
    margin: 0;
    width: 30px;
    padding: 0;
  }

  img {
    z-index: 0;
    width: 60px;
    height: 60px;
    opacity: 1;
    position: absolute;
    top: 30px;
    right: 5%;
    bottom: 0;

    &:hover {
      cursor: pointer;
    }

    @media only screen and (max-width: 555px) {
      width: 35px;
      height: 35px;
      opacity: 0.5;
      left: 15%;
    }
  }

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 24px;
      height: auto;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  ul {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    list-style: none;
    padding: 0;
    margin-top: -120px;
    width: 100%;

    @media only screen and (max-width: 555px) {
      margin-left: 10px;
      text-align: start;
    }

    li {
      height: 60px;
    }

    li.active {
      transition: all 0.5s ease-in;

      a {
        svg {
          color: #ffd700;
        }
      }
    }

    a {
      font-size: 24px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      @media only screen and (max-width: 555px) {
        font-size: 16px;
      }

      i {
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        color: #ffd700;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 14px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
      }

      &.home-link:after {
        content: 'HOME';
      }

      &.about-link:after {
        content: 'ABOUT';
      }

      &.experience-link:after {
        content: "EXPERIENCE";
      }

      &.projects-link:after {
        content: 'PROJECTS';
      }

      &.contact-link:after {
        content: 'CONTACT';
      }

      &.active svg {
        color: #ffd700;
      }
    }
  }
}

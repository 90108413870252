:root {
  --primary-color: #ffd700;
  --text-color: #fff;
  --bg-color: #181818;
  --text-spacing: 0.75px;
  --line-height: 26px;
  --font-size-base: 16px;
  --font-size-large: 18px;
  --media-large: 950px;
  --media-medium: 700px;
  --media-small: 555px;
}

.contact-page {
  margin: 0 5% 0 10%;
  color: var(--text-color);

  .text-zone {
    width: 90%;
    float: left;
    text-align: justify;
    letter-spacing: var(--text-spacing);
    line-height: var(--line-height);

    @media (max-width: 950px) {
      width: 90%;
      margin-left: 5%;
    }

    @media (max-width: 700px) {
      width: 90%;
      margin-left: 5%;
      margin-top: 5%;
      float: none;
    }

    @media (max-width: 555px) {
      width: 90%;
      margin-top: 15%;
      font-size: var(--font-size-base);
      letter-spacing: 0.5px;
      line-height: var(--line-height);
      float: none;
    }

    h1 {
      font-size: 2.5rem;

      @media (max-width: 700px) {
        font-size: 2rem;
      }

      @media (max-width: 555px) {
        font-size: 1.5rem;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 5%;

      .links {
        display: flex;
        align-items: center;
        margin: 3% 0;

        svg {
          margin-right: 3%;
        }

        a {
          text-decoration: none;
          color: var(--text-color);
          font-size: 1.2rem;
          font-weight: 700;

          &:hover {
            color: aqua;
          }

          @media (max-width: 700px) {
            font-size: 1rem;
          }

          @media (max-width: 555px) {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
  background-color: chartreuse;
}

#fp-nav ul li a span {
    background: aliceblue;
}

#fullpage > div{
  height: 100% ;
}

/* #fp-nav ul li{
    width: 100px;
    height:100px;
} */
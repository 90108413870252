.text-animate {
  display: inline-block;
  opacity: 0;
  animation: bounceIn;
  font-size: 55px;
  font-weight: 700;
  margin-right: 7px;
  font-family: 'Courier', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-right: 3px;
  animation-duration: 2s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  min-width: 10px;
}
.red{
  color: red;
}

.text-animate-hover {
  font-size: 55px;
  font-weight: 700;
  margin-right: 7px;
  font-family: 'Courier', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  
  display: inline-block;
  animation-fill-mode: both;

  &:hover {
    animation: rubberBand 1s;
    color: #ffd700;
  }
  @media (max-width: 555px){
      font-size: 35px;
    
  }
  @media only screen and (max-width: 700){
    font-size: 45px;
  }
}

@for $i from 1 through 35 {
  .text-animate._#{$i} {
    animation-delay: #{$i / 10}s;
  }
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  60% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

:root {
  --primary-color: #ffd700;
  --secondary-color: #8d8d8d;
  --bg-color: #181818;
  --font-main: 'Coolvetica', sans-serif;
  --font-secondary: sans-serif;
}

.fp-overflow {
  overflow-y: unset !important;
}

.home-page {
  margin: 0 5% 0 10%;
  display: flex;
  justify-content: space-around;
  align-items: center;


  .text-zone {
    width: 50%;
    overflow-y: hidden;

    h1 {
      color: #fff;
      font-size: 48px;
      line-height: 1.2;
      text-overflow: clip;

      margin-bottom: 20px;
      font-family: var(--font-main);
      font-weight: 400;
      transition: all 0.5s ease-in;

      @media (max-width: 768px) {
        font-size: 32px;

      }

      .small {
        font-size: 18px;
        margin: auto 7px;
      }
    }

    h2 {
      color: var(--secondary-color);
      font-size: 16px;
      font-weight: 400;
      margin-top: 10px;
      letter-spacing: 2px;
    }
  }

  .cube-spinner {
    width: 50%;
    max-width: 400px;
    text-align: center;
  }

  @media (max-width: 768px) {
    .home-page {
      flex-direction: row; /* Stack items vertically on smaller screens */
      margin: 0; 
      max-width: 90%;
      align-items: flex-start; 
      padding: 0 5px; 
    }

    .text-zone {
      width: 85%; /* Full width for text on smaller screens */
      margin-bottom: 20px; 
    }

    .cube-spinner {
      display: none;
    }
  }
}

.red {
  color: var(--primary-color);
}

.text-animate {
  animation: fadeIn 1s forwards;
}

.text-animate-hover {
  animation: fadeIn 1s 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.projects-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-left: 8%;
  
    h1 {
      margin-bottom: 40px;
      font-size: 2.5rem;
      color: #fff;
    }
  
    .carousel-container {
      position: relative;
      width: 100%;
      max-width: 1200px;
      overflow: hidden;
  
      .projects-carousel {
        display: flex;
        transition: transform 0.5s ease;
        gap: 20px;
        width: 100%; // Ensure it takes up the full width
  
        .project-card {
          flex: 0 0 calc(33.333% - 20px); 
          background: #1e1e1e;
          border-radius: 15px;
          overflow: hidden;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
          transition: transform 0.3s, box-shadow 0.3s;
          position: relative;

          
          
          
          @media (max-width: 768px) {
            
              flex: 0 0 calc(50% - 20px); 
            }
        @media (max-width: 555px) {
            flex: 0 0 calc(100% - 20px);
        }
  
          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 6px 18px rgba(0, 0, 0, 0.4);
          }
  
          img {
            width: 100%;
            height: 200px; // Fixed height for consistency
            object-fit: cover;
            transition: transform 0.3s;
          }
  
          .project-info {
            padding: 15px;
            background: #272727;
            color: #fff;
            text-align: center;
  
            h3 {
              margin-bottom: 10px;
              font-size: 1.5rem;
              color: #61dafb;
            }
  
            p {
              margin-bottom: 15px;
              font-size: 1rem;
              color: #b0b0b0;
            }
  
            .stack {
              margin: 15px 0;
  
              svg {
                margin: 0 8px;
                font-size: 1.5rem;
                color: #61dafb;
              }
            }
  
            .links {
              display: flex;
              justify-content: center;
              gap: 15px;
  
              a {
                color: #61dafb;
                text-decoration: none;
                font-size: 1.2rem;
                transition: color 0.3s;
  
                &:hover {
                  color: #fff;
                }
              }
            }
          }
        }
      }
  
      .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(0, 0, 0, 0.5);
        border: none;
        color: white;
        font-size: 2rem;
        padding: 10px;
        cursor: pointer;
        z-index: 2;
  
        &.left {
          left: 0;
        }
  
        &.right {
          right: 0;
        }
      }
    }
  }
  
  // Media queries for responsiveness
  @media (max-width: 1200px) {
    .carousel-container .projects-carousel .project-card {
      flex: 0 0 calc(50% - 20px); 
    }
  }
  
  @media (max-width: 768px) {
    .carousel-container .projects-carousel .project-card {
      flex: 0 0 calc(100% - 20px); 
    }
  
    .arrow {
      font-size: 1.5rem;
      padding: 8px;
    }
  }
  
  @media (max-width: 576px) {
    .projects-page {
      padding: 10px;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    .carousel-container .projects-carousel .project-card {
      margin: 10px 0;
    }
  }
  